import * as React from "react";
import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Grid } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import Login from "../components/forms/Login";
import Signup from "../components/forms/Signup";
import SubmitEmail from "../components/forms/SubmitEmail";
import { useUser } from "../hooks/user";
import ArtisButton from "../components/buttons/button";
import { AuthService } from "../api";
import WorkService from "../api/service";
import useCheckMobileScreen from "../hooks/isMobile";
import UserService from "../api/auth";

const validationSchema = yup.object({
  email: yup
    .string("Enter your email")
    .required("Email or username is required"),
  password: yup.string("Enter your password").required("Password is required"),
});

const signUpValidationSchema = yup.object({
  name: yup.string("Enter your name").required("Name is required"),
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
});

export default function SignIn() {
  const { user, login } = useUser();
  const isMobile = useCheckMobileScreen(600);
  const history = useHistory();
  const location = useLocation();

  let [artisCode, setArtisCode] = useState("");

  const [, setSuccess] = useState(false);
  const [, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [artisCodeError, setArtisCodeError] = useState("");

  const [, setIsMissmatch] = useState(false);
  const [submitEmail, setSubmitEmail] = useState("");
  const [openPage, setOpenPage] = useState("");
  const [hasSenderId, setHasSenderId] = useState(false);
  const [hasTransferCode, setHasTransferCode] = useState(false);

  const [senderId, setSenderId] = useState("");
  const [transfer_code, setTransferCode] = useState("");
  const [enableSignUp, setEnableSignUp] = useState(false);

  const [invitedByName, setInvitedByName] = useState("an Artis User");
  const [invitedByTransferCode, setInvitedByTransferCode] = useState(null);

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    setSubmitEmail(submitEmail);
    setOpenPage("email");

    const response = await AuthService.submitEmail({ email: submitEmail });

    if (response.result) {
      setSubmitEmail("");
      toast.success("Email submitted successfully!");
    } else {
      setSubmitEmail("");
      toast.error("Invalid email!");
    }
  };

  const formik = useFormik({
    initialValues: { email: "", password: "" },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setIsLoading(true);
      setSuccess(true);

      const loginAttempt = await login(
        values.email.toLowerCase(),
        values.password
      );
      setIsLoading(false);
      setSuccess(false);

      if (loginAttempt !== "Success") {
        formik.setFieldValue("password", "");
        setError("Invalid password or email!");
      }
    },
  });

  const signupFormik = useFormik({
    initialValues: { name: "", email: "" },
    validationSchema: signUpValidationSchema,
    onSubmit: async (values) => {
      setIsLoading(true);
      setSuccess(true);
      

      const identifier = invitedByTransferCode?.length > 0?invitedByTransferCode[0]?.holder_id:senderId;

      // Store the transfer code in localStorage if it exists
      const transferCode = invitedByTransferCode?.length > 0 && invitedByTransferCode?.[0]?.transfer_code;
      if (transferCode) {
        localStorage.setItem({"transfer_code" : transferCode});
      }
      const loginAttempt = await UserService.fameCommunitySignUp({
        invitee_name: values.name,
        invitee_email: values.email.toLowerCase(),
        sender_id: identifier,
      });

      if (loginAttempt?.result) {
        window.location.href = loginAttempt?.result?.signup_url;
      }
      else {
        if(loginAttempt.error.response) {
          toast.error(loginAttempt.error.response.data.error + '!')
        }
        else if(loginAttempt.error) {
          toast.error(loginAttempt.error + '!')
        }
        else {
          toast.error("Encountered an unknown error.")
        }
      }
      setIsLoading(false);
      setSuccess(false);
    },
  });

  useEffect(() => {
    document.title = "Login | Artis.app";
  }, []);

  useEffect(() => {
    if (user) {
      const { from } = location.state || { from: { pathname: "/works" } };
      history.push(from); // redirecting to original location
    }
  }, [user, history, location.state]);

  useEffect(() => {
    setIsMissmatch("");
  }, [formik.values.password]);

  useEffect(() => {
    setError("");
  }, [formik.values.email]);

  const handleLookup = async () => {
    // remove whitespace 
    artisCode = artisCode.trim()
    
    // if empty
    if(artisCode === '') {
      setArtisCodeError('Code cannot be empty!');
    }

    // if contains a '?'
    else if (artisCode.includes('?')) {
      const parts = artisCode.split('?');
      const codePart = parts[1];
      
      // Check if there's something after '?'
      if (codePart && codePart.trim() !== '') {
        const response = await WorkService.getCertificatesDetails(
          codePart, // The part after '?'
          parts[0]  // The part before '?'
        );
  
        if (response.result) {
          history.push(`/work/${artisCode}`);
        } else {
          setArtisCodeError(`No work or certificate is registered under this code: ${artisCode}`);
        }
        
      } else {
        setArtisCodeError('Invalid format. Please make sure to provide a valid code after "?".');
      }
    } 
    
    // if dosen't contain '?' 
    else {
      const lookupAttempt = await WorkService.lookup(artisCode);

      if(lookupAttempt.result) {
        history.push(`/works/${artisCode}`);
      } else {
        setArtisCodeError(`Nothing is registered under this code: ${artisCode}`);
      }
    }
  };
  

  useEffect(() => {
    if (hasSenderId) {
      setOpenPage("login");
    }
  }, [hasSenderId]);

  useEffect(() => {
    if (hasTransferCode) {
      setOpenPage("login");
    }
  }, [hasTransferCode]);


  useEffect(() => {
    const getSenderId = async () => {
      // get sender_id from the link
      const urlParams = new URLSearchParams(window.location.search);
      const senderId = urlParams.get("sender_id");
     if(senderId){
       const res = await WorkService.getUserProfile({
         sender_id: senderId,
       })
      if (res?.result) {
        setInvitedByName(res?.result?.profile?.first_name + " " + res?.result?.profile?.last_name)
      }
      else { window.location.href = '/login';}
      if (senderId) {
        setHasSenderId(true);
        setSenderId(senderId);
        setEnableSignUp(true);
      }
    }
  }
    getSenderId();
  }, []);
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const transferCode = urlParams.get("transfer_code");
    // Function to get transfer code details
    const getTransferCode = async () => {
      if (transferCode) {
        const resp = await WorkService.getCertificatesTransfer(transferCode);
        if (resp?.result) {
          setInvitedByTransferCode(resp?.result);
        }
        else { window.location.href = '/login';}
        if(transferCode){
          setHasTransferCode(true);
          setTransferCode(transferCode);
          setEnableSignUp(true);
        }
      }
    };
      getTransferCode();
  }, []);

  return (
    <Container maxWidth="md">
      <CssBaseline />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {
          ((!hasSenderId && !enableSignUp) || (!hasTransferCode && !enableSignUp))  && (
            <Box>
              <Grid
                sx={{
                  fontFamily: "Bellefair, serif",
                  textAlign: "center",
                }}
                container
              >
                <Grid item>
                  <ArtisButton
                    name="Login/Sign up"
                    onClick={() => setOpenPage(openPage === "login" ? "" : "login")}
                    sx={{
                      fontSize: "1.3em",
                    }}
                    textColor={openPage === "login" ? "gray" : "teal"}
                  />
                </Grid>
                <Grid item>
                  <ArtisButton
                    name="Find Copyright"
                    onClick={() =>
                      setOpenPage(openPage === "artis-code" ? "" : "artis-code")
                    }
                    sx={{
                      fontSize: "1.3em",
                    }}
                    textColor={openPage === "artis-code" ? "gray" : "teal"}
                  />
                </Grid>
              </Grid>
            </Box>
          )
        }
        <Box maxWidth={isMobile ? "95%" : "50%"}>
          <Collapse in={openPage === "login"} timeout="auto" unmountOnExit>
            <Typography color="red">
              {error !== "" ? `${error}` : ""}
            </Typography>
            {
              (hasSenderId && enableSignUp) || (hasTransferCode && enableSignUp) ?
                <Signup
                  formik={signupFormik}
                  setEnableSignUp={setEnableSignUp}
                  invitedByName={invitedByName}
                  setHasSenderId={setHasSenderId}
                  setHasTransferCode={setHasTransferCode}
                  transfer_code={transfer_code}
                  getCertificateData={invitedByTransferCode}
                  // onStateChange={handleChildData}
                />
                :
                <Login
                  formik={formik}
                // hasSenderId={hasSenderId}
                // setEnableSignUp={setEnableSignUp}
                />
            }
            {
              (!hasSenderId && !enableSignUp)  && (
                <SubmitEmail
                  isMobile={isMobile}
                  handleEmailSubmit={handleEmailSubmit}
                  submitEmail={submitEmail}
                  setSubmitEmail={setSubmitEmail}
                  setError={setError}
                />
              )
            }
          </Collapse>
        </Box>
        <Box maxWidth={isMobile ? "95%" : "55%"}>
          <Collapse in={openPage === "artis-code"} timeout="auto" unmountOnExit>
            <Box>
              <br />
              <p class="center">Find a copyrighted work by its Artis Code</p>
              <br />
              <Typography color="red">
                {artisCodeError !== "" ? `${artisCodeError}` : ""}
              </Typography>
              <TextField
                style={{ textTransform: "none" }}
                variant="standard"
                margin="normal"
                required
                fullWidth
                name="artis_code"
                label="Artis Code"
                type="text"
                id="name"
                value={artisCode}
                onChange={(e) => {
                  setArtisCode(e.target.value);
                  setArtisCodeError("");
                }}
                autoComplete="off"
                sx={{ mb: 3 }}
                InputProps={{
                  style: {
                    fontSize: "1.3rem",
                  },
                }}
                InputLabelProps={{ required: false, sx: { color: "#9E9E9E" } }}
              />
              <Button
                type="button"
                className="normal"
                fullWidth
                style={{
                  backgroundColor: "transparent",
                  fontSize: "1.5em",
                  color: "teal",
                  textTransform: "none",
                  fontFamily: "Bellefair, serif",
                }}
                variant="text"
                sx={{ mt: -1, mb: 2 }}
                onClick={handleLookup}
              >
                Start Search
              </Button>
            </Box>
          </Collapse>
        </Box>
        <Box maxWidth={isMobile ? "95%" : "55%"} textAlign="center">
        </Box>
        {
          
          <Container style={{textAlign: 'center'}}>
              <br/>
              <br/>
              
             
              <img alt="Artis.app Logo" src="images/Artis.png" height="416" width= "300" />
            
              <br />
              <br />
             
             
          </Container>
        
        }
      </Box>
     <Box maxWidth="md">
    <CssBaseline />
   
    {/* Footer */}
    <Box
      component="footer"
      sx={{
        mt: 4,
        py: 2,
        textAlign: "center",
        fontFamily: "Bellefair, serif",
        fontSize: "1.1rem",
        color: "teal"
     
      }}
    >
      ADVISES, PROTECTS, PROMOTES,<br />AND MAKES CREATIVITY PROFITABLE<br />FOR EVERYONE IN THE WORLD<br /><br />easy digital copyrights<br />digital certificates of authenticity<br />fights copyright theft<br /><br  />
      <br />Based in Hollywood, California, USA<br /> <br /><br />

     Founded by Hollywood filmmakers and cryptoholics<br />
  <a
    href="https://en.wikipedia.org/wiki/Martin_Kunert" // Replace with the correct URL
    target="_blank"
    rel="noopener noreferrer"
    style={{
      textDecoration: "none",
      color: "teal", // Matches the footer's color
    }}
  >
    Martin Kunert
  </a>{" "}
  and{" "}
  <a
    href="https://en.wikipedia.org/wiki/Eric_Manes" // Replace with the correct URL
    target="_blank"
    rel="noopener noreferrer"
    style={{
      textDecoration: "none",
      color: "teal", // Matches the footer's color
    }}
  >
    Eric Manes
  </a>
  <br />
  trusted by<br /><br />
   <img alt="Disney logo" src="images/disney.jpg" height="75" width= "115"  /><br />
   <img alt="Universal Studios logo" src="images/universal.jpg" height="57" width= "115" /><br />
   <img alt="Sony Pictures logo" src="images/sony.jpg" height="75" width= "115" /><br />
    

      
  <br /><br />copyright mmxxv artis.app inc.
    </Box>
  </Box>
    </Container>
   

      
  );
}
