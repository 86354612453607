import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';

import { useHistory } from "react-router";

import useMobileCheckScreen from '../hooks/isMobile';

export default function ArtisFooter() {
    const isMobile = useMobileCheckScreen();
    const history = useHistory();
    const handleHelp = () => {
        history.push("/help");
    };
    return (
        <Box
            component="footer"
            sx={{
                fontFamily: "Montserrat, sans-serif",
                fontWeight: "normal",
                fontSize: isMobile ? "0.8rem" : "1rem",
                textAlign: "center",
                display: "flex",
                flexDirection: "column"
            }}
        >
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "2%",
                }}
            >
                {
                    history.location.pathname !== "/help" &&
                    <Typography
                        component="div"
                        alignItems={"center"}
                        sx={{
                            marginRight: 1,
                            textTransform: "capitalize",
                            cursor: "pointer",
                            color: "#607d8b",
                            fontFamily: "Montserrat, sans-serif",
                        }}
                        onClick={handleHelp}
                    >
                        HELP
                    </Typography>
                }
                <br /><br />
                <Typography
                    sx={{
                        fontFamily: "Montserrat, sans-serif",
                        color: "#607d8b",
                        fontSize: isMobile ? "0.8rem" : "1rem",
                    }}
                >
                    © 2024 Artis.app Inc
                </Typography>
            </div>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Link
                    style={{
                        fontFamily: "Montserrat, sans-serif",
                        color: "#607d8b",
                        fontWeight: "normal",
                        fontSize: isMobile ? "0.8rem" : "1rem",
                        margin: "2%",
                    }}
                    to="/terms"
                >
                    terms of use
                </Link>
                <Link
                    style={{
                        fontFamily: "Montserrat, sans-serif",
                        color: "#607d8b",
                        fontWeight: "normal",
                        fontSize: isMobile ? "0.8rem" : "1rem",
                        margin: "2%",
                    }}
                    to="/team"
                >
                    team
                </Link>
                <Link
                    style={{
                        fontFamily: "Montserrat, sans-serif",
                        color: "#607d8b",
                        fontWeight: "normal",
                        fontSize: isMobile ? "0.8rem" : "1rem",
                        margin: "2%",
                    }}
                    to="/contact"
                >
                    contact
                </Link>
            </div>
        </Box>
    );
}
