import React from 'react'
import { Box, Typography } from "@mui/material";

export default function AiAgent() {
  return (
    <Box>
        <Typography 
            sx={{
                textAlign: 'center',
                marginTop: '3rem',
                marginBottom: '3rem'
            }}
        >
            Your personalised <span style={{color: 'green'}}>https://Artis.app</span> AI agent, who guides your career and protects your work -- is under development.
        </Typography>
    </Box>
  )
}
